import { promises as fs } from 'fs';
import { MyPageContext } from 'kp';
import { join } from 'path';

import isServer from '@lib/ssrSetup/isServer';

const maintenanceMode = async (
  ctx: MyPageContext,
  next: () => Promise<void>
) => {
  const { req, res } = ctx;
  const isInMaintenanceMode = process.env.MAINTENANCE_MODE === 'true';

  const skipMiddleware = !req || !isServer;
  if (skipMiddleware) return next();

  if (isInMaintenanceMode) {
    const filePath = join(process.cwd(), 'public', '503.html');
    const fileContent = await fs.readFile(filePath, 'utf-8');
    res.setHeader('Cache-Control', 'public,max-age=31536000,immutable');
    res.setHeader('Content-Type', 'text/html');
    res.end(fileContent);

    return true;
  }

  return next();
};

export default maintenanceMode;
