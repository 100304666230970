import { searchEvents } from '@lib/events/search.event';
import { formatSearchParams } from 'src/lib/formatSearchParams';

import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';

import type { SearchPayload, SearchResponse } from './type';

const SEARCH_URL = 'search';

const fetchSearchedAds = (thunkAPI: ThunkAPI) => (payload: SearchPayload) => {
  const searchParamsObj = { ...formatSearchParams(payload.query) };
  const searchParams = new URLSearchParams(searchParamsObj).toString();

  searchEvents.search({ filters: searchParamsObj });

  return apiRequest<SearchResponse>(`${SEARCH_URL}?${searchParams}`, {
    method: 'GET',
    thunkAPI,
  });
};

const fetchCategoryNewestSearchedAds =
  (thunkAPI: ThunkAPI) => (payload: SearchPayload) => {
    const searchParamsObj = { ...formatSearchParams(payload.query) };
    const searchParams = new URLSearchParams(searchParamsObj).toString();

    searchEvents.search({ filters: searchParamsObj });

    return apiRequest<SearchResponse>(`category-ads?${searchParams}`, {
      method: 'GET',
      thunkAPI,
    });
  };

const fetchNewestSearchedAds =
  (thunkAPI: ThunkAPI) => (payload: SearchPayload) => {
    const searchParamsObj = { ...formatSearchParams(payload.query) };
    const searchParams = new URLSearchParams(searchParamsObj).toString();

    searchEvents.search({ filters: searchParamsObj });

    return apiRequest<SearchResponse>(`newest-ads?${searchParams}`, {
      method: 'GET',
      thunkAPI,
    });
  };

export const searchAPI = {
  fetchSearchedAds,
  fetchNewestSearchedAds,
  fetchCategoryNewestSearchedAds,
} as const;
