import React, { PropsWithChildren } from 'react';
import dynamic from 'next/dynamic';
import { compose } from 'redux';
import { MyAppInitialProps, MyAppProps, MyPageContext } from 'kp';

import { getMetaTags } from '@lib/metaTags/getMetaTags';
import { ROOT_URL } from 'src/lib/constants/env.constants';

import { withAuthSyncComponent } from '@hocs/withAuthSync';
import { withPrefetchComponent } from '@hocs/withPrefetch';

import useBanner from '@hooks/banner/useBanner';

import { handleAccessLoginControlOnServerSide } from '@store/api/request/handleAccessLoginControl';
import { fetchCategoryBox } from '@store/categoryBox/thunk';
import { fetchHeadlineCategories } from '@store/categoryHeadline/thunk';
import { selectIsMobile } from '@store/meta/selector';
import { setPageType } from '@store/meta/slice';
import { fetchNewestSearchedAds } from '@store/search/thunk';
import { wrapper } from 'src/store/configureStore';

import { mainLayout } from '@layouts/desktop/ThreeColumnLayout/layout';
import { mobileMainLayout } from '@layouts/mobile/MobileMainLayout/layout';

import Seo from '@components/seo/responsive/Seo';
import OrganizationSchemaLd from '@components/seo/responsive/seo-schemas/OrganizationSchemaLd';
import SiteLinksSearchBoxSchemaLd from '@components/seo/responsive/seo-schemas/SiteLinksSearchBoxSchemaLd';

const HomePage = dynamic(() => import('@pages/desktop/IndexPage/HomePage'));
const HomePageMobile = dynamic(
  () => import('@pages/mobile/HomePageMobile/HomePageMobile')
);

const HomePageContainer = ({ isMobile }: MyAppInitialProps) => {
  useBanner({ page: 'home', variable: { isMobile } });

  const {
    title = '',
    description = '',
    ogImage = '/static/images/meta/original.png',
    ignoreSuffix = true,
  } = getMetaTags('home');

  return (
    <>
      <Seo
        title={title}
        description={description}
        openGraph={{ title, description, images: [{ url: ogImage }] }}
        ignoreSuffix={ignoreSuffix}
      />
      <SiteLinksSearchBoxSchemaLd route={ROOT_URL} />
      <OrganizationSchemaLd />

      {isMobile ? <HomePageMobile /> : <HomePage />}
    </>
  );
};

HomePageContainer.getLayout = mainLayout;
HomePageContainer.getLayoutMobile = (
  page: PropsWithChildren<{ props: MyAppProps }>
) => {
  return mobileMainLayout({
    ...page,
    props: {
      ...page.props,
      isHeaderFixed: false,
    },
  });
};

HomePageContainer.getInitialProps = wrapper.getInitialAppProps(
  (store) => async (ctx: MyPageContext) => {
    const { req } = ctx;
    const isMobile = selectIsMobile(store.getState());
    store.dispatch(setPageType('index'));

    if (req) {
      await store.dispatch(fetchHeadlineCategories());
      if (isMobile) {
        await store
          .dispatch(
            fetchNewestSearchedAds({
              query: { order: 'newest', period: 'today' },
            })
          )
          .then((payload) => {
            handleAccessLoginControlOnServerSide({
              payload,
              store,
              ctx,
            });
          });
      } else {
        await store.dispatch(fetchCategoryBox({ kind: 'goods' }));
      }
      return { pageProps: {} };
    }
    store.dispatch(fetchHeadlineCategories());
    if (isMobile) {
      await store.dispatch(
        fetchNewestSearchedAds({ query: { order: 'newest', period: 'today' } })
      );
    } else {
      await store.dispatch(fetchCategoryBox({ kind: 'goods' }));
    }
    return { pageProps: {} };
  }
);
export default compose(
  withAuthSyncComponent,
  withPrefetchComponent
)(HomePageContainer);
