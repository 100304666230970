/* eslint-disable no-console */
import nextCookie from 'next-cookies';
import { randomBytes } from 'crypto';
import { MyPageContext } from 'kp';
import os from 'os';

import isServer from '@lib/ssrSetup/isServer';

const setLocals = async (ctx: MyPageContext, next: () => Promise<void>) => {
  const { req, res, query } = ctx;

  const skipMiddleware = !req || !isServer;
  if (skipMiddleware) return next();

  const cookies = req.headers.cookie ? nextCookie(ctx) : {};

  res.locals.nonce = randomBytes(8).toString('base64');
  res.locals.wtgEnabled = true;
  const forwardedFor = req.headers['x-forwarded-for'];
  res.locals.ip = Array.isArray(forwardedFor)
    ? forwardedFor[0]
    : forwardedFor || req.socket.remoteAddress;
  const portHeader = req.headers['MNS-Client-Source-Port'];
  res.locals.port = Array.isArray(portHeader)
    ? portHeader[0]
    : portHeader || req.socket.remotePort;
  res.locals.pid = process.pid || '';
  res.locals.hostname = os.hostname() || '';
  res.locals.session = cookies.KUPUJEMPRODAJEM || '';

  if (query?.debug) {
    console.log(`PID:${process.pid} Setting`, res.locals);
    console.log(`PID:${process.pid} Setting with SESSION`, res.locals);
  }

  return next();
};

export default setLocals;
