/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import Cookies from 'js-cookie';

import { COOKIE_URL } from 'src/lib/constants/env.constants';
import { getRecentFilterIds } from 'src/lib/getRecentFilterIds';
import { normalize } from 'src/lib/normalize';

import { GeneralStatsAPI } from '@store/generalStats/api';
import { FiltersInfoPayload } from '@store/generalStats/type';
import { createAppAsyncThunk } from '@store/global/utils';
import { searchAPI } from '@store/search/api';
import { seoTextAPI } from '@store/seoText/api';

import { SearchPayload } from './type';
import {
  checkLastSearchQuery,
  handleAreYouHumanHardError,
  handleAreYouHumanSoftError,
} from './utils';

export const fetchSearchRecent = createAppAsyncThunk(
  'search/fetchSearchRecent',
  async (payload: FiltersInfoPayload, thunkAPI) => {
    const response = await GeneralStatsAPI.fetchFiltersInfo(thunkAPI)(payload);
    return response.data.results;
  }
);

export const fetchSearchedAds = createAppAsyncThunk(
  'search/fetchSearchedAds',
  async (payload: SearchPayload, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    try {
      const { follow, order } = payload.query;

      const lastSearchResult = checkLastSearchQuery(getState, payload);
      if (lastSearchResult) {
        return lastSearchResult;
      }

      const response = await searchAPI.fetchSearchedAds(thunkAPI)(payload);

      const { filterId } = response.data.results;
      const canSaveToRecent = follow !== 'yes' && order !== 'newest';
      if (canSaveToRecent) {
        let filterIds = getRecentFilterIds();
        const filteredFilterIds = filterIds.filter((item) => item !== filterId);
        filterIds = [...filteredFilterIds, filterId];
        filterIds = filterIds.slice(Math.max(filterIds.length - 11, 0));
        Cookies.set('recentSearchFilterIds', JSON.stringify(filterIds), {
          expires: 1000,
          path: '/',
          domain: COOKIE_URL,
        });
      }

      handleAreYouHumanSoftError(response, dispatch);

      return response.data.results;
    } catch (error) {
      const response = error.response.data;
      handleAreYouHumanHardError(response, dispatch);
      return rejectWithValue(response);
    }
  }
);

export const fetchNewestSearchedAds = createAppAsyncThunk(
  'search/fetchNewestSearchedAds',
  async (payload: SearchPayload, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    try {
      const lastSearchResult = checkLastSearchQuery(getState, payload);
      if (lastSearchResult) {
        return lastSearchResult;
      }

      const isGeneralNewestAdsPageEnabled =
        getState()?.stats?.featureFlags?.isGeneralNewestAdsPageEnabled;

      const response = isGeneralNewestAdsPageEnabled
        ? await searchAPI.fetchNewestSearchedAds(thunkAPI)(payload)
        : await searchAPI.fetchSearchedAds(thunkAPI)(payload);

      handleAreYouHumanSoftError(response, dispatch);

      return response.data.results;
    } catch (error) {
      const response = error.response.data;
      handleAreYouHumanHardError(response, dispatch);
      return rejectWithValue(response);
    }
  }
);

export const fetchCategoryNewestSearchedAds = createAppAsyncThunk(
  'search/fetchSearchedAds',
  async (payload: SearchPayload, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      const response = await searchAPI.fetchCategoryNewestSearchedAds(thunkAPI)(
        payload
      );
      handleAreYouHumanSoftError(response, dispatch);

      return response.data.results;
    } catch (error) {
      const response = error.response.data;
      handleAreYouHumanHardError(response, dispatch);
      return rejectWithValue(response);
    }
  }
);

export const fetchSeoCategoryText = createAppAsyncThunk(
  'search/fetchSeoCategoryText',
  async (payload: number, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await seoTextAPI.fetchSeoCategoryText(thunkAPI)(payload);
      return res.data.results;
    } catch (error) {
      return rejectWithValue(
        normalize(error.response.data.errors, 'error_code')
      );
    }
  }
);
