import isEmpty from 'lodash/isEmpty';

import sanitizeQueryString from '@lib/router/utils/sanitizeQueryString';

type HandleParams = {
  url: string;
  matchedParams: Record<string, string>;
  query: URLSearchParams;
};

type RedirectRoute = {
  pattern: RegExp;
  handler({ url, matchedParams, query }: HandleParams): string;
};

export const OLD_URLS_MAPPER = {
  user: {
    ad_list: '/moj-kp/moji-oglasi',
    welcome: '/moj-kp/moji-oglasi',
    favorites: '/moj-kp/pratim',
    edit: '/moj-kp/moj-nalog',
    card_management: '/moj-kp/moj-nalog/moje-platne-kartice',
    gdpr_request: '/moj-kp/moj-nalog/moji-podaci',
    renewing: '/moj-kp/kp-obnavljac',
    kpizlog_stat: '/moj-kp/kp-izlog',
    transactions: '/moj-kp/prepaid',
    transaction_guide_new: '/moj-kp/prepaid/dopuna',
    invoices: '/moj-kp/racuni',
    popup_ipg_receipt: '/moj-kp/popup-placanje',
    ipg_receipt: '/moj-kp/placanje',
    popup_ipg_failed: '/moj-kp/popup-placanje',
    ipg_failed: '/moj-kp/placanje',
  },
  notification: {
    list: '/moj-kp/obavestenja',
  },
  review: {
    list: '/moj-kp/ocene',
  },
  addressbook: {
    list: '/moj-kp/adresar',
  },
  message: {
    inbox: '/moj-kp/poruke/inbox',
    thread: '/moj-kp/poruke',
  },
};

export const REDIRECT_ROUTES: RedirectRoute[] = [
  {
    pattern: /\/(?<type>user|notification|review|addressbook|message)[.]php/,
    handler: ({ url, matchedParams, query }) => {
      const action = query.get('action');
      const userId = query.get('user_id');
      const newUrl = OLD_URLS_MAPPER[matchedParams.type]?.[action];
      query.delete('action');

      if (action === 'thread' && userId) {
        query.set('user2Id', userId);
        query.delete('user_id');
      }

      if (newUrl) {
        const queryString = query.toString();
        const redirectUrl = !isEmpty(queryString)
          ? `${newUrl}?${queryString}`
          : newUrl;

        return redirectUrl;
      }

      return `${process.env.API_URL}${url}`;
    },
  },
  {
    pattern: /(ad|oglas)[.]php/,
    handler: ({ query }) => {
      const urls = {
        service: `/moj-kp/obnova-oglasa/${query.get('ad_id')}`,
        promo: `/moj-kp/promocije/${query.get('ad_id')}`,
      };

      const newUrl = urls[query.get('action')];

      if (newUrl) return newUrl;

      const queryString = query.toString();
      return `${process.env.API_URL}${queryString ? `?${queryString}` : ''}`;
    },
  },
  {
    pattern:
      /(?<category>[a-zA-Z0-9_-]+)\/(?<subcategory>[a-zA-Z0-9_-]+)\/(?<slug>[a-zA-Z0-9_-]*)-(?<id>[0-9]*)-oglas[.]htm/,
    handler: ({ matchedParams }) => {
      return `/${matchedParams.category}/${matchedParams.subcategory}/${matchedParams.slug}/oglas/${matchedParams.id}`.toLowerCase();
    },
  },
  {
    pattern: /(?<category>[a-zA-Z0-9_ -]+)\/(?<id>[0-9]*)-kategorija[.]htm/,
    handler: ({ matchedParams }) => {
      return `/${matchedParams.category}/kategorija/${matchedParams.id}`.toLowerCase();
    },
  },
  {
    pattern:
      /(?<category>[a-zA-Z0-9_ -]+)\/(?<subcategory>[a-zA-Z0-9_() -]*)\/(?<slug>[a-z0-9]*)-(?<id1>[0-9]*)-(?<id2>[0-9]*)-grupa[.]htm/,
    handler: ({ matchedParams }) => {
      return `/${matchedParams.category}/${matchedParams.subcategory}/grupa/${matchedParams.slug}/${matchedParams.id1}/${matchedParams.id2}`.toLowerCase();
    },
  },
  {
    pattern:
      /(?<category>[a-zA-Z0-9_ -]+)\/(?<group>[a-zA-Z0-9_ -]+)\/search[.]php/,
    handler: ({ matchedParams, query }) => {
      const queryString = sanitizeQueryString(query);
      return `/${matchedParams.category.toLowerCase()}/${matchedParams.group.toLowerCase()}/pretraga${
        queryString ? `?${queryString}` : ''
      }`;
    },
  },
  {
    pattern: /(?<category>[a-zA-Z0-9_ -]+)\/search[.]php/,
    handler: ({ matchedParams, query }) => {
      const queryString = sanitizeQueryString(query);
      return `/${matchedParams.category.toLowerCase()}/pretraga${
        queryString ? `?${queryString}` : ''
      }`;
    },
  },
  {
    pattern: /\/(bClick)[.]php/,
    handler: ({ url, query }) => {
      const queryString = query.toString();
      const redirectUrl = !isEmpty(queryString) ? `${url}?${queryString}` : url;

      return `${process.env.API_URL}${redirectUrl}`;
    },
  },
  {
    pattern: /search[.]php/,
    handler: ({ query }) => {
      const queryString = sanitizeQueryString(query);
      return `/pretraga${queryString ? `?${queryString}` : ''}`;
    },
  },
  {
    pattern: /(?<filename>[a-zA-Z0-9_\-.]+)[.]php/,
    handler: ({ matchedParams, query }) => {
      return Object.keys(query).length === 0
        ? `/${matchedParams.filename}`
        : `/${matchedParams.filename}?${sanitizeQueryString(query)}`;
    },
  },
  {
    pattern: /(?<filename>[a-zA-Z0-9_\-.]+)[.]htm(?![\w\d])/,
    handler: ({ matchedParams }) => {
      return `/${matchedParams.filename}`.toLowerCase();
    },
  },
];
