import isEmpty from 'lodash/isEmpty';
import { MyPageContext } from 'kp';
import { URLSearchParams } from 'url';

import isBotOrExtension from '@lib/isBotOrExtension';
import redirectRequest from '@lib/redirectRequest';
import isServer from '@lib/ssrSetup/isServer';

const QUERY_PARAMS_TO_CHECK = new Set([
  'desktopRequested',
  'desktop_requested',
  'order',
  'not_user_ids',
  'list_type',
  'dummy',
  // 'action',
  // 'listType',
  // 'search',
  // 'prevKeywords',
  // 'adKind',
]);

const excludeSpecificQueryParamsBot = async (
  ctx: MyPageContext,
  next: () => Promise<void>
) => {
  const { req, asPath } = ctx;
  const skipMiddleware = !req || !isServer || !isBotOrExtension(req);
  if (skipMiddleware) return next();

  const splitResult = asPath?.split('?');

  const baseUrl = splitResult[0];
  const params = new URLSearchParams(splitResult[1]);

  let hasExcludedParams = false;

  QUERY_PARAMS_TO_CHECK.forEach((param) => {
    if (params.has(param)) {
      params.delete(param);
      hasExcludedParams = true;
    }
  });

  if (!hasExcludedParams) {
    return next();
  }

  const paramsString = params.toString();
  const redirectUrl = isEmpty(paramsString)
    ? baseUrl
    : `${baseUrl}?${paramsString}`;

  redirectRequest({ ctx, url: redirectUrl });
  return true;
};

export default excludeSpecificQueryParamsBot;
