import Router from 'next/router';
import { MyPageContext } from 'kp';

import isServer from './ssrSetup/isServer';

type RedirectRequestProps = {
  ctx: MyPageContext;
  url: string;
  status?: number;
};

const redirectRequest = ({
  ctx: { res },
  url,
  status = 301,
}: RedirectRequestProps) => {
  if (res && isServer) {
    if (res.writeHead && !res.headersSent) {
      // const encodedUrl = encodeURI(url);
      const encodedUrl = url;

      res.writeHead(status, {
        Location: encodedUrl,
      });

      res.end();
    }
  } else {
    Router.push(url);
  }
};

export default redirectRequest;
