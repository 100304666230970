/* eslint-disable consistent-return */
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { MyPageContext } from 'kp';

import redirectRequest from '@lib/redirectRequest';
import isServer from '@lib/ssrSetup/isServer';

const sanitizeRoute = async (ctx: MyPageContext, next: () => Promise<void>) => {
  const { req, asPath } = ctx;

  if (!req || !isServer) return next();

  const splitResult = asPath?.split('?');

  const baseUrl = splitResult[0];

  const sanitizedBaseUrl = baseUrl.replace(/\/{2,}/g, '/');

  if (!isEqual(sanitizedBaseUrl, baseUrl)) {
    const redirectUrl = isEmpty(splitResult?.[1])
      ? sanitizedBaseUrl
      : `${sanitizedBaseUrl}?${splitResult?.[1]}`;

    redirectRequest({ ctx, url: redirectUrl });
    return true;
  }

  return next();
};

export default sanitizeRoute;
