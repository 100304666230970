import { makeQueryString } from '@api/request/makeQueryString';

import type { ThunkAPI } from '@store/configureStore';

import { apiRequest } from '../api/request/makeRequest';

import type {
  NotificationListPayload,
  NotificationListResponse,
  ReadNotification,
  ReadNotificationResponse,
} from './type';

const NOTIFICATION_URL = 'notifications';

const read =
  (thunkAPI: ThunkAPI) =>
  ({ id }: ReadNotification) => {
    return apiRequest<ReadNotificationResponse>(`${NOTIFICATION_URL}/read`, {
      method: 'POST',
      body: { id },
      thunkAPI,
    });
  };

const list =
  (thunkAPI: ThunkAPI) =>
  (query: NotificationListPayload = { page: 1 }) => {
    const queryString = makeQueryString(query);
    return apiRequest<NotificationListResponse>(
      `${NOTIFICATION_URL}/list?${queryString}`,
      {
        method: 'GET',
        thunkAPI,
      }
    );
  };

export const NotificationAPI = {
  read,
  list,
} as const;
