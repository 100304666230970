/* eslint-disable no-console */
import { MyPageContext } from 'kp';

import checkWhitelistedIpSourceMaps from '@lib/ssrSetup/checkWhitelistedIpSourceMaps';
import getIpAddress from '@lib/ssrSetup/getIpAddress';
import isServer from '@lib/ssrSetup/isServer';

const sourceMaps = async (ctx: MyPageContext, next: () => Promise<void>) => {
  const { req, res, asPath } = ctx;

  const skipMiddleware = !req || !isServer;
  if (skipMiddleware) return next();

  const ip = getIpAddress(req);
  const devTools = process.env.DEVTOOLS_ENABLED === 'true';
  const dev = process.env.NODE_ENV !== 'production';
  const sentryToken = process.env.X_SENTRY_TOKEN;

  if (asPath.includes('.map')) {
    if (req.headers['x-sentry-token']) {
      console.log(req.headers['x-sentry-token'], sentryToken);
    }

    if (devTools || checkWhitelistedIpSourceMaps(ip)) {
      return next();
    }

    const isNotAuthorized =
      !dev && !!sentryToken && req.headers['x-sentry-token'] !== sentryToken;

    if (isNotAuthorized) {
      res.statusCode = 401;
      res.end(
        JSON.stringify({
          error:
            'Authentication access token is required to access the source map.',
        })
      );
      return true;
    }
  }

  return next();
};

export default sourceMaps;
